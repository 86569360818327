import React from 'react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const LocationList = ({ fields }) => {
  return (
    <div className="location-list l-padding">
      {fields.officeListing.map((listItem) => (
        <React.Fragment key={listItem.id}>
          <h2 className="location-list-title">
            {listItem.fields.heading.value}
          </h2>
          <div className="location-list-flex clearfix">
            {listItem.fields.officeList.map((office) => (
              <div key={office.id} className="location-list-item">
                <h3 className="location-list-item-title">
                  {office.fields.title.value}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: office.fields.description.value,
                  }}
                />
              </div>
            ))}
            {listItem.fields.subGroupingList.map((subGrouping) => (
              <React.Fragment key={subGrouping.id}>
                <h3>{subGrouping.fields.heading.value}</h3>
                <div className="location-list-flex clearfix">
                  {subGrouping.fields.officeList.map((regionalChild) => (
                    <div key={regionalChild.id} className="location-list-item">
                      <h4 className="location-list-item-title">
                        {regionalChild.fields.title.value}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: regionalChild.fields.description.value,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

LocationList.propTypes = PropTypes.SitecoreItem.inject({
  officeListing: PropTypes.Multilist.inject({
    heading: PropTypes.SingleLineText,
    officeList: PropTypes.Multilist.inject(PropTypes.ContentBlock),
    subGrouping: PropTypes.Multilist.inject({
      heading: PropTypes.SingleLineText,
      officeList: PropTypes.Multilist.inject(PropTypes.ContentBlock),
    }),
  }),
});

LocationList.defaultProps = {
  fields: {
    officeListing: DefaultProps.Multilist,
  },
};

export default LocationList;
